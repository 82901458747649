import { Link } from 'react-router-dom'; 

import github from '../assets/icon/github.png';
import linkedin from '../assets/icon/linkedin.png';

import '../index.scss'

function Footer() {
    return (
        <footer className="footer_wrapper">
            <div>
            <Link target='_blank' className='github_link' to="https://github.com/jocelynboucheny">
                <img className='githubLogo' src={ github } alt="logo" />
            </Link>
            <Link target='_blank' className='linkedin_link' to="https://www.linkedin.com/in/jocelyn-boucheny-358988272">
                <img className='linkedinLogo' src={ linkedin } alt="logo" />
            </Link>
            </div>
        </footer> 
    )
}

export default Footer