import "../index.scss"
import aboutPicture from '../assets/moi.png';

function Skills() {
    return (
        <div className='about_wrapper'>
            <h2 className="about_title">Présentation</h2>
            <section className="aboutDetails_wrapper">
                <img src={ aboutPicture } alt="aboutPicture" className="about_picture" />
                <p className="about_description">Bonjour !<br/><br/>Je m'appelle Jocelyn et je suis passionné par le développement web.<br/><br/>J'utilise différents langages comme React, JavaScript ou encore des outils comme Github.<br/></p>
            </section>
        </div>
    )
}

export default Skills