import "../index.scss"
import css from '../assets/icon/css.webp';
import github from '../assets/icon/github.png';
import html from '../assets/icon/html.webp';
import java from '../assets/icon/java.png';
import sass from '../assets/icon/sass.png';
import swagger from '../assets/icon/swagger.png';
import redux from '../assets/icon/redux.png';
import react from '../assets/icon/react.png';

function Skills() {
    return (
        <div className='skills_wrapper'>
            <h2 className="skills_title">Compétences</h2>
            <section className="skills_wrapper">
                <p className="skills_description">Outils et langages </p>
                <img src={ react } alt="" className="skill_icon" /><span >React</span>
                <img src={ redux } alt="" className="skill_icon" /><span >Redux</span>
                <img src={ github  } alt="" className="skill_icon" /><span >Github</span>
                <img src={ css } alt="" className="skill_icon" /><span >Css</span>
                <img src={ html } alt="" className="skill_icon" /><span >Html</span>
                <img src={ java } alt="" className="skill_icon" /><span >Javascript</span>
                <img src={ sass } alt="" className="skill_icon" /><span >Sass</span>
                <img src={ swagger } alt="" className="skill_icon" /><span >Swagger</span>
            </section>
        </div>
    )
}

export default Skills